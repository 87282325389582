import { combineReducers } from 'redux'
import { History } from 'history'
import { connectRouter } from 'connected-react-router'
import { user, userList } from './user/reducers'
import settings from './settings/reducers'
import settingsReducer from '../redux/settings/settingsSlice'
import userReducer, { userListReducer } from '../redux/user/userSlice'
import { jobs } from './jobs/reducers'
import collection from './questionCollection/reducers'
import { platformGroupReducer as platformGroups } from './platform/reducers'
import subjects from './subjects/slice'
import modules from './modules/slice'
import topics from './topics/slice'
import languages from './languages/reducers'
import questions from './questions/reducers'
import discusses from './discuss/slice'
import {
  hDVideos,
  videoPacks,
  videoPlaylists,
  videos,
  sortedVideoPacks,
  qaVideos,
} from './videos/slice'
import lookups from './lookups/slice'
import { testSeriesPacks, testSeries } from './exams/slice'
import accessControls from './accessControls/slice'
import trackCategory from './trackCategory/slice'
import tracks from './tracks/slice'
import platforms from './platforms/slice'
import packs from './packs/slice'
import studyPlans from './studyplan/slice'
import filters from './filters/slice'
import skilling from './skillingPacks/slice'
import questionsMapping from './mapping/slice'
import batchListing from './batches/slice'
import studentsList from "./students/slice"
import categoriesList from './landingPageGlobalComponents/slice'
import teachers from './teachers/slice'
import pricingProductPackages from './pricingAndCoupons/ProductPackages/ListSlice'
import plans from './pricingAndCoupons/Plans/ListSlice'
import purchases from './goldPurchase/slice'
import goldQuestions from './goldQuestions/slice'
import productPackages from './productPackage/slice'
import batchReport from './batchReport/slice'
import reportedQuestions from './reportedQuestions/reportedQuestionsSlice'
import languagesV2 from './languages/languageSlice'
import slackUsers from './slackUsers/slackUserSlice'
import {
  liveClassesStudyPlan,
  liveClassesStudyPlanBatches,
  liveClasses,
  licensedUsers,
  elevatePacks
} from './liveclasses/slice'
import staffProfiles, { staffProfile } from './profile/slice'
import studentAttendance from './attendance/slice';
import elevateBatches from './elevateBatches/slice';
import elevatePurchases from './elevatePurchase/slice';
import elevateBatchReport from './elevateBatchReport/slice';
import elevateCourses from './elevateCourses/slice';
import newElevateBatchReport from './newElevateBatchReport/slice';
export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    settings,
    settingsReducer,
    userReducer,
    userListReducer,
    user,
    userList,
    jobs,
    collection,
    platformGroups,
    subjects,
    modules,
    topics,
    languages,
    questions,
    discusses,
    hDVideos,
    videoPacks,
    sortedVideoPacks,
    videoPlaylists,
    videos,
    lookups,
    qaVideos,
    testSeriesPacks,
    testSeries,
    tracks,
    accessControls,
    trackCategory,
    platforms,
    packs,
    studyPlans,
    filters,
    skilling,
    teachers,
    purchases,
    goldQuestions,
    productPackages,
    reportedQuestions,
    languagesV2, // TODO combing v2 with old languages
    slackUsers,
    pricingProductPackages,
    plans,
    batchReport,
    liveClassesStudyPlan,
    liveClassesStudyPlanBatches,
    liveClasses,
    licensedUsers,
    staffProfiles,
    staffProfile,
    studentAttendance,
    elevatePacks,
    elevateBatches,
    elevatePurchases,
    elevateBatchReport,
    newElevateBatchReport,
    questionsMapping,
    categoriesList,
    elevateCourses,
    batchListing,
    studentsList
  })
